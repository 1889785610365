import GlobalHoverService from 'o365.controls.GlobalHoverService.ts';
import OrgUnitRolesHover from 'system.vue.components.OrgUnitRolesHover.vue';

export default {
    mounted(el: HTMLElement, binding: any) {
        const service = GlobalHoverService.getService();
        service.bindElement(el, {
            component: OrgUnitRolesHover,
            props: {
                roleId: binding.value.roleId,
                orgUnitId: binding.value.orgUnitId,
            },
        })
    },
    updated(el: HTMLElement, binding: any) {
        const service = GlobalHoverService.getService();
        const props = service.getPropsFromEl(el)
        if (props && props.roleId !== binding.value) {
            props.roleId = binding.value;
        }
    },
    unmounted(el: HTMLElement, _binding: any) {
        const service = GlobalHoverService.getService();
        service.unbindElement(el);
    }
};